import FaqTabCard from "@/components/Global/FaqTabCard"
import FaqTabs from "@/components/Global/FaqTabs"
import { activeFaq } from "@/stores/faqStore"
import { useStore } from "@nanostores/react"
import type { CollectionEntry } from "astro:content"
import { useEffect } from "react"
import { type faqOptions } from "src/types/faq.types"

interface FaqContainerProps {
  patientFaqs: CollectionEntry<faqOptions>[]
  therapistFaqs: CollectionEntry<faqOptions>[]
  organisationFaqs: CollectionEntry<faqOptions>[]
}

const FaqContainer = (props: FaqContainerProps) => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const faqParam = urlParams.get("faq")

    if (faqParam === "patiens") {
      activeFaq.set("faq-patient")
    } else if (faqParam === "therapists") {
      activeFaq.set("faq-therapist")
    } else if (faqParam === "organisations") {
      activeFaq.set("faq-organisation")
    }
  }, [])

  const $activeFaq = useStore(activeFaq)
  const faqs = (() => {
    switch ($activeFaq) {
      case "faq-patient":
        return props.patientFaqs
      case "faq-therapist":
        return props.therapistFaqs
      case "faq-organisation":
        return props.organisationFaqs
      default:
        return null
    }
  })()

  return (
    <div className="grid justify-center text-center sm:bg-cyan-100  sm:py-40 lg:rounded-[40px]">
      <div className="bg-cyan-100 p-3 sm:m-3 sm:rounded-[40px] lg:w-[776px]">
        <h2 className="mx-5 mb-11 mt-14 text-center text-3xl font-normal leading-[54px] text-slate-800 sm:mb-20 sm:mt-0 sm:text-5xl">
          Frequently Asked Questions
        </h2>
        <div className=" mb-12">
          <FaqTabs />
        </div>

        <div className="mb-32 sm:mb-0">
          {faqs?.map((faq) => <FaqTabCard key={faq.id} faq={faq} />)}
        </div>
      </div>
    </div>
  )
}

export default FaqContainer
