import { activeFaq } from "@/stores/faqStore"
import { useStore } from "@nanostores/react"
import { type faqOptions } from "src/types/faq.types"

interface FaqTabItemProps {
  id: faqOptions
  label: string
}

const FaqTabItem = (props: FaqTabItemProps) => {
  const { id, label } = props

  const $activeTab = useStore(activeFaq)
  const isActive = $activeTab === id

  const tabClasses = isActive ? " border-b-2 border-blue-600 " : " border-b border-slate-800 "

  const tabButtonClasses = isActive ? "font-bold text-blue-600" : "font-normal text-slate-800"

  return (
    <div
      className={`inline-flex flex-grow items-end justify-center gap-3  px-5 py-3 ${tabClasses}`}
    >
      <button
        id={id}
        onClick={() => activeFaq.set(id)}
        className={`text-center text-sm font-bold leading-7 text-blue-600 sm:text-xl ${tabButtonClasses}`}
      >
        {label}
      </button>
    </div>
  )
}

export default FaqTabItem
