import { cn } from "@/lib/utils/styling"
import { type CollectionEntry } from "astro:content"
import { useState } from "react"
import { type faqOptions } from "src/types/faq.types"

// Import the CSS file containing transition styles

interface FaqTabCardProps {
  faq: CollectionEntry<faqOptions>
}

const FaqTabCard = (props: FaqTabCardProps) => {
  const { faq } = props

  const [collapsed, setCollapsed] = useState(true)

  const handleClick = () => {
    setCollapsed(!collapsed)
  }

  const collapseBtnClasses = collapsed ? "" : "rotate-45"
  const answerClasses = collapsed ? "max-h-0 overflow-hidden " : "max-h-[300px]" // Toggle the show class based on collapsed state

  return (
    <button
      onClick={handleClick}
      className="mb-4 inline-flex w-full flex-col items-start justify-start rounded-[20px] border border-violet-100 bg-white py-5 pl-6 pr-5 text-left shadow"
    >
      <div className="mb-3 flex w-full items-center justify-between">
        <p className="text-left font-bold">{faq.data.question}</p>
        <span className="inline-flex h-7 w-7 items-center justify-center rounded-full bg-indigo-50 p-0.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={cn(
              "h-4 w-4 text-brand-accent transition duration-150 ease-linear",
              collapseBtnClasses
            )}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
        </span>
      </div>

      <p
        className={cn(
          "text-base font-normal leading-normal text-gray-500 transition-[max-height]",
          answerClasses
        )}
      >
        {faq.data.answer}
      </p>
    </button>
  )
}

export default FaqTabCard
