import FaqTabItem from "@/components/Global/FaqTabButton"

const FaqTabs = () => {
  return (
    <div className="inline-flex w-full items-start justify-start">
      <FaqTabItem id="faq-patient" label="Patients" />
      <FaqTabItem id="faq-organisation" label="Organisations" />
      <FaqTabItem id="faq-therapist" label="Therapists" />
    </div>
  )
}

export default FaqTabs
